import value from "./value.js";
import numberArray, { isNumberArray } from "./numberArray.js";
export default function (a, b) {
  return (isNumberArray(b) ? numberArray : genericArray)(a, b);
}
export function genericArray(a, b) {
  var nb = b ? b.length : 0,
    na = a ? Math.min(nb, a.length) : 0,
    x = new Array(na),
    c = new Array(nb),
    i;
  for (i = 0; i < na; ++i) x[i] = value(a[i], b[i]);
  for (; i < nb; ++i) c[i] = b[i];
  return function (t) {
    for (i = 0; i < na; ++i) c[i] = x[i](t);
    return c;
  };
}